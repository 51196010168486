.card {
    border: var(--default-border) ;
    border-radius: 5px;
    padding: 1em;
    margin: 1em;
    display: inline-block;
    background: var(--background-color-light);
}

.card > :first-child {
    font-weight: bold;
    border-bottom: var(--default-border);
    padding-bottom: 1em;
}

.card > :nth-child(2) {
    padding-top: 0.5em;
}
