.tree-node {
    padding-left: 1em;

}

.tree-view > input[type="checkbox"] {
    vertical-align: middle;
}

.tree-view > ol {
    margin-top:0;
    padding-left: 0;
    list-style-type: none;
}

.tree-expander {
    width: 1em;
    display: inline-block;
}
.tree-header *  {
    vertical-align: middle;
}
