.navigation {
    background: var(--background-color);
    height: var(--navigation-bar-height);
    position: sticky;
    top: 0px;
    z-index:100;
}



.navigation li {
    display: inline-block;
    padding-left: 1em;
    padding-right: 1em;
    border-right: 1px solid var(--default-border-color);
    margin-top:auto;
    margin-bottom:auto;

}

.navigation > ul >  * {
    margin:auto;
}

.navigation li.selected {
    text-decoration: underline;
}

.navigation ul {
    margin:0;
    padding:0;
    border-top: 1px solid 1px solid var(--default-border-color);
    border-bottom: 1px solid 1px solid var(--default-border-color);
    height: var(--navigation-bar-height);
    display: flex;
}

.navigation a {
    text-decoration: none;
}

.navigation + * {
    overflow-y: auto;
    flex-grow: 1;
}
