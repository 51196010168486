header {
    text-align: center;
}

html {
    height: 100%;
}

body {
    margin: 0;
    height: 100%;
}
.top {
    /*display: flex;*/
    /*background: red;*/
    flex-direction: row;
    /*height: 50%;*/
    height: 600px;
}

.left {
    width: 200px;
    background: lightcoral;
    display: inline-block;
}

.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.app-content {
    flex-grow:1;
    max-height: calc( 100vh - var(--navigation-bar-height));

}
