.dashboard-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-grow:1;
    /*max-height: calc( 100vh - var(--navigation-bar-height));*/
}


.dashboard-menu {
    width: 15em;
    overflow-y: auto;
}

.dashboard-body {
    flex-grow: 1;
    overflow-y: auto;
}
