:root {
    /*--text-padding: 0.8rem;*/
    --default-border-size: 0.05em;
    /*--default-border-color: #999;*/

    --background-hue: 120;
    --background-saturation: 50%;
    --background-luminance: 95%;
    --almost-black: #eee;
    --default-border-color: hsl(var(--background-hue), var(--background-saturation), calc(var(--background-luminance) - 5%));
    --default-border: var(--default-border-size)  var(--default-border-color) solid;
    --default-border-radius: 0.25em;
    --shadow-color: hsl(var(--background-hue), var(--background-saturation), calc(var(--background-luminance) - 5%));
    /*--transition-time: 0.2s;*/
    --background-color:  hsl(var(--background-hue), var(--background-saturation), var(--background-luminance));
    --background-color-dark:  hsl(var(--background-hue), var(--background-saturation), calc(var(--background-luminance) - 4%));
    --background-color-light: hsl(var(--background-hue), var(--background-saturation), calc(var(--background-luminance) + 4%));
    /*--transparent-background-color: hsla(var(--background-hue), var(--background-saturation), var(--background-luminance), 50%);*/
    /*--header-height: 3.5em;*/
    --navigation-bar-height: 3em;
    --default-margin-size: 1em;
    --half-margin-size: 0.5em;
}

* {
    box-sizing: border-box;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*max-width: 1280px;*/
    margin-left: auto;
    margin-right: auto;
}

main {
    width: 100%;
}

main > *{
    max-width: 80em;

    margin:auto;
    width: fit-content;

}



h2 {
    margin-left: 1em;
    margin-right: 1em;
    text-align: center;
    padding-bottom: 1em;
    border-bottom: var(--default-border);
}

h4 {
    text-align: center;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}





.clickable {
    cursor: pointer;
}

.button-row {
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
}

.button-row > button:not(:first-child) {
    margin-left: 0.5em;
}

.button-row > button:not(:last-child) {
    margin-right: 0.5em;
}

.row {
    display: flex;
}

.row > *:not(:first-child) {
    margin-left: 0.5em;
}

.row > *:not(:last-child) {
    margin-right: 0.5em;
}


.spacer {
    flex-grow: 1;
}


.form-row input {
    width: 100%;
}

input[type=checkbox] {
    width: auto;
    vertical-align: middle;
    margin-right: 0.5em;
}

button {
    cursor: pointer;
}

input,button, select {
    border: var(--default-border);
    border-radius: var(--default-border-radius);
    height: 2em;
    padding-left:0.5em;
    padding-right: 0.5em;
}

input {
    margin-right: 1em;
    background: var(--background-color-light);
}

button {
    background: var(--background-color-dark);
}

.error {
    background-color: lightcoral;
    padding-left: 0.5em;
    padding-right: 0.5em;
    border-radius: var(--default-border-radius);
}

table {
    width: 100%;
}

td {
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.pf-box {
    /*border: 1px solid black;*/
    /*border-radius: 5px;*/
    /*padding:1em;*/
}

.pf-big {
    font-size: 1.2em;
    line-height: 2em;
    margin-left: 1em;
}


.pf-labels {
    display:grid;
    grid-template-columns: 50% 50%;
    max-width: 80em;
    margin:auto;
}

.pf-label {
    display: flex;
    flex-direction: row;

}

.pf-label > * {
    flex-basis: 0px;
    flex-grow: 1;
}

section {
    min-height: 70px;
    padding-bottom: 1em;
}

select {
    background: white;
    padding: 0.25em;
}


.grid {
    display: grid;
    grid-template-columns: 50% 50%;
}

.columns-2 {
    grid-column-end: span 2;
}

.disabled {
    color: grey;
}

