.side-menu {
    padding: 1em;
    background: var(--background-color);
    min-height: 100%;
}

.side-menu select {
    width: 100%;
}

.side-menu input[type='date'] {
    width: 100%;
}
