.backoffice-customer-list {
    displaY: flex;
    flex-direction: column;
    align-items: stretch;
}

.backoffice-customer-list > .indent-2 > * {
    margin-left: 2em;
}

.backoffice-customer-list > .indent-3 > * {
    margin-left: 3em;
}

.backoffice-customer-list > .indent-4 > * {
    margin-left: 4em;
}

.backoffice-customer-list > .indent-5 > * {
    margin-left: 5em;
}




.backoffice {
    width: 100%;
}
.backoffice > *{
    min-width: 80em;

    margin:auto;
    width: fit-content;

}

.customer-header {
    display: flex;
    min-width: 50vw;
}

.customer-header :first-child {
    flex-grow: 1;

}

.backoffice-user-list {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto auto;
    grid-column-gap: 1em;
    grid-row-gap: 0.5em;
}

.backoffice-leaf-sensor-list {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    grid-column-gap: 1em;
    grid-row-gap: 0.5em;
}


.backoffice-row {
    display: contents;
}

.backoffice-row > * {
    margin-left: 0.5em;
    margih-right: 0.5em;
    flex-grow: 1;
}

.backoffice-row .card {
    width: 100%;
}
