.weather-icon {
    position: absolute;
    height: 24px;
}

.weather-line {
    position: relative;
    /* Fixme: make this a parameter used both here
        and in Chart
     */
    margin-left: 80px;
    margin-right: 30px;
}