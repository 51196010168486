.spinner-container {
    height: 100%;

}
.spinner {
    position: relative;;
    top: 50%;
    border: 16px solid var(--background-color); /* Light grey */
    border-top: 16px solid var(--background-color-dark); /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    margin:auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
