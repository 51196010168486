.map-filter {
    position: absolute;
    right: 1em;
    margin-top: 1em;
    z-index: 1;
}

.map-container {
    display: contents;
}
#map {
    width: 100%;
    display: inline-block;
    height: 75%;
}

/*.ol-attribution {*/
/*    visibility: hidden;*/
/*}*/

